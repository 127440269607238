import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

// Need version 3.x.x to be imported without issue with Parcel
import * as pdfjsLib from "pdfjs-dist";
// Always make sure copy this worker file from `node_modules/pdfjs-dist/build/`
pdfjsLib.GlobalWorkerOptions.workerSrc = 'pdf.worker.min.js';

// Make sure dayJS can parse different kind of date formats
dayjs.extend(customParseFormat)

export const TokenType = Object.freeze({
  TEXT: "text",
  CURRENCY: "currency",
  CURRENCY_DR: "currency_overdraft",
  CURRENCY_PLUS: "currency_plus",
  CURRENCY_MINUS: "currency_minus",
  DATE_TIME: "date_time",
});

export const getDocument = pdfjsLib.getDocument;

export function findDateTime(text) {
  const formats = ['DD/MM', 'DD/MM/YY', 'DD/MM/YYYY', 'DD-MMM-YYYY', 'DD MMM', 'hh:mm A', 'hh:mm'];
  if (dayjs(text, formats, true).isValid()) {
    return true;
  }
  return false;
}


function findCurrency(text) {
  // 1. Check for basic structure, including optional <space><char><char> at the end
  if (!/^(\d+(,\d{3})*)?(\.\d{2})?([+-])?( \w{2})?$/.test(text)) {
    return [false, null];
  }

  // 2. Extract potential sign (either +/- or two-letter code)
  let sign = null;
  const match = text.match(/ (\w{2})$/); // Check for space and two characters at the end
  if (match) {
    sign = "DR"
  } else if (text.slice(-1) === '+' || text.slice(-1) === '-') {
    sign = text.slice(-1); // Extract +/- sign if present
  }

  // 3. Remove sign, currency symbols, then convert and validate
  let strippedText = text;
  if (sign) {
    strippedText = text.slice(0, sign.length === 1 ? -1 : -3); // Remove sign (either 1 or 3 chars long)
  }
  strippedText = strippedText.replace(/[^\d.,-]/g, '');

  try {
    const asNumber = parseFloat(strippedText.replace(",", ""));
    return [!isNaN(asNumber), sign];
  } catch (error) {
    return [false, null];
  }
}

function findType(string) {
  if (findDateTime(string)) {
    return TokenType.DATE_TIME;
  }

  const [isCurrency, sign] = findCurrency(string);
  if (isCurrency) {
    if (sign != null) {
      if (sign === '+')
        return TokenType.CURRENCY_PLUS;
      else if (sign === '-')
        return TokenType.CURRENCY_MINUS;
      else
        return TokenType.CURRENCY_DR;
    } else
      return TokenType.CURRENCY;
  }

  // If both conversions fail, it's a string
  return TokenType.TEXT;
}


export function generateToken(index, content) {
  const [scaleX, bobo, dada, scaleY, tx, ty] = content.transform;

  const type = findType(content.str);

  // console.debug(`${index} (${ty}): ${content.str}`);
  const token = {
    index: index,
    type,
    // debugString: content.str,
    x: tx,
    y: ty
  };

  let storedText = content.str;

  if (type == TokenType.CURRENCY_MINUS || type == TokenType.CURRENCY_PLUS) {
    storedText = content.str.slice(0, -1);
  } else if (type == TokenType.CURRENCY_DR) {
    storedText = `(${content.str.slice(0, -3)})`;
  }

  return { token, storedText }
}

function processDuplicateKeys(data) {
  const keyCounts = {};
  const processedData = {};

  for (const [key, value] of data) {
    keyCounts[key] = (keyCounts[key] || 0) + 1;

    const processedKey = keyCounts[key] > 1 ? `${key}_${keyCounts[key] - 1}` : key;
    processedData[processedKey] = value;
  }

  return processedData;
}

export function extractSerialNumbers(text) {
  const serialNumberPattern = /\b(?:(?<serial1>[a-zA-Z]{2} \d{8,} [a-zA-Z])|(?<serial2>[a-zA-Z]{3,}\d{3,}-?\d{2,})|(?<serial3>[a-zA-Z]{2,}\d{4,})|(?<serial4>\d{6,}))\b/gm;

  const matches = Array.from(text.matchAll(serialNumberPattern)).map(match => {
    const serial = Object.keys(match.groups).find(group => match.groups[group] !== undefined) || 'unknown';
    return [serial, match[0]];
  });

  return processDuplicateKeys(matches);
}

export function splitDescription(text, titlePrefix) {
  const lines = text.split('\n');
  const result = {};

  let currentTitleNumber = 1;
  for (const line of lines) {
    if (line.trim()) {
      const title = `${titlePrefix}${currentTitleNumber}`;
      result[title] = line.trim();
      currentTitleNumber++;
    }
  }

  return result;
}

export function swapCharacters(text) {
  function getRandomCharacter(str) {
    return str[Math.floor(Math.random() * str.length)];
  }

  const vowels = "aeiouAEIOU";
  const consonants = "bcdfghjklmnpqrstvwxyzBCDFGHJKLMNPQRSTVWXYZ";

  if (findDateTime(text)) {
    // Skip if it's a valid date, time, or datetime 
    return text;
  }

  return text.split('').map(char => {
    const isUpper = char === char.toUpperCase();
    let replacementChar;

    if (vowels.includes(char.toLowerCase())) {
      // Replace vowel while maintaining case
      replacementChar = getRandomCharacter(vowels);
    } else if (consonants.includes(char.toLowerCase())) {
      // Replace consonant while maintaining case
      replacementChar = getRandomCharacter(consonants);
    } else if (/[1-9]/.test(char)) {
      // Replace non-zero numbers
      replacementChar = String(Math.floor(Math.random() * 9) + 1);
    } else {
      // Keep other characters as they are
      return char;
    }

    // Apply correct case to replacement character
    return isUpper ? replacementChar.toUpperCase() : replacementChar.toLowerCase();
  }).join('');
}


